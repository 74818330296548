// TODO include radium

PerksAndUpsells = React.createClass({
  propTypes: {
    upsellCategories: React.PropTypes.object.isRequired,
    selectedUpsells: React.PropTypes.arrayOf(React.PropTypes.string),
    section: React.PropTypes.string.isRequired,
    isUsingShiftUpsells: React.PropTypes.bool,
    tagGroups: React.PropTypes.arrayOf(React.PropTypes.object),
    selectedTags: React.PropTypes.arrayOf(React.PropTypes.string),
  },

  getInitialState() {
    const hasUpsellsSelected = !_.isEmpty(this.props.selectedUpsells)
    return {
      selectUpsellsMap: hasUpsellsSelected
        ? _.reduce(
            this.props.upsellCategories,
            (result, upsellEntity, upsellId) => {
              const extendResult = {}
              extendResult[upsellId] = {
                isSelected: this.props.selectedUpsells.indexOf(upsellId) >= 0,
                label: upsellEntity.label,
              }
              return _.assign(result, extendResult)
            },
            {}
          )
        : this.props.upsellCategories,
      addUpsells: hasUpsellsSelected,
      isUsingShiftUpsells: this.props.isUsingShiftUpsells,
      tagGroups: this.props.tagGroups,
      selectedTags: this.props.selectedTags
    }
  },

  handleOnClick() {
    this.setState({ addUpsells: true })
  },

  handleOffClick() {
    this.setState({ addUpsells: false })
  },

  handleUseShiftClick() {
    this.setState({ isUsingShiftUpsells: !this.state.isUsingShiftUpsells })
  },

  upsellClickHandler(upsellId) {
    const extendUpsellsMap = {}
    extendUpsellsMap[upsellId] = _.assign({}, this.state.selectUpsellsMap[upsellId], {
      isSelected: !this.state.selectUpsellsMap[upsellId].isSelected,
    })
    this.setState({
      selectUpsellsMap: _.assign({}, this.state.selectUpsellsMap, extendUpsellsMap),
    })
  },

  render() {
    return (
      <div
        style={{
          padding: '12px 0',
        }}
      >
        {this.props.section === 'ACCESS' &&
          <CheckboxLine
            key="sr-checkbox-is-using-shift"
            label="Follow upgrades configured for this shift"
            isSelected={this.state.isUsingShiftUpsells}
            onClick={this.handleUseShiftClick}
          />}
        {!this.state.isUsingShiftUpsells &&
          <div>
            <span
              style={{
                display: 'inline-block',
                fontWeight: '600',
                width: '100%',
                margin: '35px 0 10px',
                color: 'rgba(83, 83, 83, 1.00)',
              }}
            >
              Add Selectable Upgrades
            </span>
            <ToggleButton
              value={this.state.addUpsells}
              onOnClick={this.handleOnClick}
              onOffClick={this.handleOffClick}
            />
            {this.state.addUpsells &&
              <div
                style={{
                  marginBottom: '20px',
                }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    fontWeight: '600',
                    width: '100%',
                    margin: '40px 0 10px',
                    color: 'rgba(83, 83, 83, 1.00)',
                  }}
                >
                  Which upgrade categories?
                </span>
                <MultiSelect testId="sr-select-choose-category"
                  selectUpsellsMap={this.state.selectUpsellsMap}
                  upsellClickHandler={this.upsellClickHandler}
                />
              </div>}
          </div>}
        {this.props.section === 'ACCESS' &&
          <input
            type="text"
            name="is_using_shift_upsells"
            data-test="sr-input-is-using-shift-upsells"
            value={this.state.isUsingShiftUpsells ? 'true' : 'false'}
            style={{ display: 'none' }}
          />}
        <input
          type="text"
          name="add_upsells"
          data-test="sr-input-add-upsells"
          value={this.state.addUpsells ? 'true' : 'false'}
          style={{ display: 'none' }}
        />
        <input
          type="text"
          name="selected_upsells"
          data-test="sr-input-selected_upsells"
          value={_.reduce(
            this.state.selectUpsellsMap,
            (result, upsellEntity, upsellId) =>
              upsellEntity.isSelected ? result.concat(upsellId) : result,
            []
          ).toString()}
          style={{ display: 'none' }}
        />
        {this.props.section === 'ACCESS' &&
          <GenericTagsDropDown
            tagGroups={this.state.tagGroups}
            tagsSelected={this.state.selectedTags}
          />}
      </div>
    )
  },
})

const ToggleButton = (props) => {
  const buttonStyling = {
    display: 'inline-block',
    width: '50px',
    height: '38px',
    lineHeight: '38px',
    fontWeight: '300',
    fontSize: '13px',
    textAlign: 'center',
    padding: '0',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    boxSizing: 'inherit',
  }

  return (
    <div
      style={{
        display: 'inline-block',
        border: '1px solid rgba(216, 221, 229, 1.00)',
        borderRadius: '5px',
        backgroundColor: 'white',
        fontFamily: 'Roboto',
        boxSizing: 'border-box',
      }}
    >
      <div
        onClick={props.onOnClick}
        value={props.value}
        data-test="sr-toggle-button-on"
        style={_.assign({}, buttonStyling, {
          borderRadius: '5px 0 0 5px',
          color: props.value ? 'rgba(100, 146, 236, 1.00)' : 'rgba(203, 203, 203, 1.00)',
          backgroundColor: props.value ? 'rgba(203, 224, 253, 1.00)' : 'white',
        })}
      >
        Yes
      </div>
      <div
        onClick={props.onOffClick}
        value={props.value}
        data-test="sr-toggle-button-off"
        style={_.assign({}, buttonStyling, {
          borderRadius: '0 5px 5px 0',
          color: props.value ? 'rgba(203, 203, 203, 1.00)' : 'rgba(100, 146, 236, 1.00)',
          backgroundColor: props.value ? 'white' : 'rgba(203, 224, 253, 1.00)',
        })}
      >
        No
      </div>
    </div>
  )
}

const MultiSelect = props => (
  <div
    data-test={props.testId}
    style={{
      position: 'relative',
      width: '90%',
      boxShadow: '0px 0px 5px -2px rgba(0,0,0,0.75)',
      boxSizing: 'inherit',
    }}
  >
    <MultiSelectInput selectUpsellsMap={props.selectUpsellsMap} />
    <MultiSelectDropdown
      selectUpsellsMap={props.selectUpsellsMap}
      upsellClickHandler={props.upsellClickHandler}
    />
  </div>
)

const MultiSelectInput = props => (
  <div
    onClick={props.onClick}
    style={{
      width: '100%',
      minHeight: '27px',
      padding: '4px',
      border: '1px solid rgba(218, 218, 218, 1.00)',
      backgroundColor: 'white',
      borderRadius: '3px',
      cursor: 'pointer',
      fontFamily: 'inherit',
      boxSizing: 'inherit',
    }}
  >
    {_.reduce(
      props.selectUpsellsMap,
      (result, upsellEntity, upsellId) =>
        upsellEntity.isSelected
          ? result.concat(
            <div
              key={`sr-option-${upsellEntity.label}`}
              style={{
                display: 'inline-block',
                height: '23px',
                lineHeight: '23px',
                fontSize: '14px',
                padding: '0px 5px',
                margin: '1px 3px',
                border: '1px solid rgba(141, 141, 141, 1.00)',
                borderRadius: '3px',
                color: 'rgba(141, 141, 141, 1.00)',
                backgroundColor: 'rgba(241, 241, 241, 1.00)',
                boxSizing: 'inherit',
              }}
            >
              {upsellEntity.label}
            </div>
            )
          : result,
      []
    )}
  </div>
)

const MultiSelectDropdown = props => (
  <div
    style={{
      width: '100%',
      padding: '5px',
      marginTop: '-1px',
      border: '1px solid rgba(218,218,218,1.00)',
      boxShadow: '0px 1px 5px -2px rgba(0,0,0,0.75)',
      backgroundColor: 'white',
      zIndex: '501',
      boxSizing: 'inherit',
    }}
  >
    {_.map(props.selectUpsellsMap, (upsellEntity, upsellId) => (
      <CheckboxLine
        key={`sr-checkbox-${upsellEntity.label}`}
        label={upsellEntity.label}
        isSelected={upsellEntity.isSelected}
        onClick={props.upsellClickHandler.bind(this, upsellId)}
      />
    ))}
  </div>
)

const CheckboxLine = props => (
  <div data-test={props.key}
    style={{
      height: '18px',
      padding: '5px 0 5px 24px',
      position: 'relative',
      boxSizing: 'inherit',
    }}
  >
    <CheckboxSymbol onClick={props.onClick} isSelected={props.isSelected} />
    <div
      onClick={props.onClick}
      style={{
        fontSize: '14px',
        lineHeight: '18px',
        fontFamily: 'inherit',
        display: 'inline-block',
        boxSizing: 'inherit',
        cursor: 'pointer',
        color: 'rgba(154, 154, 154, 1.00)',
      }}
    >
      {props.label}
    </div>
  </div>
)

const CheckboxSymbol = props => (
  <div
    onClick={props.onClick}
    style={{
      width: '16px',
      height: '16px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'rgba(204, 204, 204, 1.00)',
      borderRadius: '2px',
      display: 'inline-block',
      position: 'absolute',
      left: '0',
      boxSizing: 'inherit',
      backgroundColor: 'white',
      cursor: 'pointer',
    }}
  >
    {props.isSelected &&
      <img
        src={`${Pmp.Settings.MEDIA_URL}images/checkbox.png`}
        style={{
          width: '16px',
          height: '16px',
        }}
      />}
  </div>
)
